import React, { useCallback, useEffect, useRef, useState } from 'react';

import { DownloadIcon, PaperClipIcon, XIcon } from '@heroicons/react/outline';
import axios from 'axios';
import cx from 'classnames';

const StreamCargo = ({
  url,
  filename,
  size,
}: {
  url: string;
  filename: string;
  size: number;
}) => {
  const [rate, setRate] = useState(0);
  const [objUrl, setObjUrl] = useState('');
  const [status, setStatus] = useState('');

  const abortControllerRef = useRef(new AbortController());
  const download = useCallback(() => {
    axios
      .post(
        '/api/d',
        { url },
        {
          responseType: 'blob',
          signal: abortControllerRef.current.signal,
          onDownloadProgress: (progressEvent: ProgressEvent) => {
            setRate(progressEvent.loaded / size);
          },
        }
      )
      .then((response) => {
        const objUrl = window.URL.createObjectURL(new Blob([response.data]));
        console.log('Success');
        setObjUrl(objUrl);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [url, size]);

  useEffect(() => {
    console.log('useEffect');
    download();
  }, [download]);

  return (
    <div className="flex items-end">
      <div className="flex-1">
        <div className="flex h-8 items-center">
          <PaperClipIcon className="box-content h-4 w-4 pr-2 text-cyan-800" />
          <a
            href={url}
            target="_blank"
            rel="noreferrer"
            className="text-base font-medium text-sky-900"
          >
            {url}
          </a>
          <p className="flex-1" />
          <span className="text-sm font-medium text-cyan-800">
            {Math.floor(rate * 100)}%
          </span>
        </div>
        <div className="my-1 h-2.5 w-full rounded-full bg-gray-200">
          <div
            className={cx({
              'h-2.5 rounded-full bg-cyan-600 transition-[width]': true,
              'bg-gray-600': status === 'aborted',
            })}
            style={{ width: `${rate * 100}%` }}
          />
        </div>
      </div>

      <div className="mb-1">
        {objUrl ? (
          <a
            className="btn btn-icon ml-3 h-8 w-8"
            href={objUrl}
            download={filename}
          >
            <DownloadIcon />
          </a>
        ) : (
          <button
            className="btn btn-icon ml-3 h-8 w-8"
            onClick={() => {
              abortControllerRef.current.abort();
              setStatus('aborted');
            }}
            disabled={status === 'aborted'}
          >
            <XIcon />
          </button>
        )}
      </div>
    </div>
  );
};

export default StreamCargo;
