import React, { useEffect, useState } from 'react';

import { ClockIcon } from '@heroicons/react/outline';
import axios from 'axios';
import { formatISO9075 } from 'date-fns';

import StreamCargo from './StreamCargo';

type UnknownCargoInfo = {
  type: 'unknown';
};

type StreamCargoInfo = {
  type: 'stream';
  filename: string;
  size: number;
};

type CargoInfo = UnknownCargoInfo | StreamCargoInfo;

const defaultCargoInfo: UnknownCargoInfo = { type: 'unknown' };

const renderCargoInfo = (info: CargoInfo, url: string): React.ReactNode => {
  console.log({ fn: 'renderCargoInfo', info, url });

  switch (info.type) {
    case 'stream':
      return (
        <StreamCargo url={url} filename={info.filename} size={info.size} />
      );
    default:
      return null;
  }
};

const Cargo = ({ url }: { url: string }) => {
  const [date] = useState(() => new Date());
  const [cargoInfo, setCargoInfo] = useState<CargoInfo>(defaultCargoInfo);

  useEffect(() => {
    console.log('Cargo - useEffect');

    axios.post('/api/d', { url, header: true }).then((response) => {
      const contentLength = response.data['content-length'] || '0';
      const contentDisposition = response.data['content-disposition'] || '';

      setCargoInfo({
        type: 'stream',
        filename: contentDisposition.includes('filename')
          ? contentDisposition.split('filename=')[1]
          : url.split('/').pop()!.split('#')[0].split('?')[0],
        size: parseInt(contentLength),
      });
    });
  }, [url]);

  return (
    <div className="mx-0 my-4 rounded bg-white px-3 py-2 shadow-md">
      <div className="flex h-8 items-center">
        <ClockIcon className="box-content h-4 w-4 pr-2 text-cyan-800" />
        <p>{formatISO9075(date)}</p>
      </div>

      {renderCargoInfo(cargoInfo, url)}
    </div>
  );
};

export default Cargo;
