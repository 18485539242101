import React, { useId, useState } from 'react';
import { useForm } from 'react-hook-form';

import { v1 as uuidv1 } from 'uuid';

import Cargo from './components/Cargo';

type FromData = {
  url: string;
};

type BoatData = {
  id: string;
  url: string;
};

const App = () => {
  const formId = useId();
  const [boats, setBoats] = useState<BoatData[]>([]);
  const { register, reset, handleSubmit } = useForm<FromData>();

  const onSubmit = handleSubmit((data) => {
    console.log('handleSubmit');
    data.url &&
      setBoats((boats) => [
        {
          id: uuidv1(),
          url: data.url,
        },
        ...boats,
      ]);
    reset({ url: '' });
  });

  return (
    <div className="container mx-auto rounded-lg bg-teal-50 px-4 py-2 shadow-sm">
      <form className="my-2 flex items-center" onSubmit={onSubmit}>
        <label htmlFor={`${formId}-url`} className="py-2">
          URL:
        </label>
        <input
          type="text"
          id={`${formId}-url`}
          className="mx-4 flex-1 rounded border-gray-300 shadow-sm focus:border-cyan-400 focus:ring focus:ring-cyan-300 focus:ring-opacity-50"
          {...register('url')}
        />
        <button type="submit" className="btn btn-primary">
          Add
        </button>
      </form>

      <hr className="my-2 border-cyan-800" />

      {boats.map((boat) => (
        <Cargo key={boat.id} url={boat.url} />
      ))}
    </div>
  );
};

export default App;
